import React, { useState, useEffect } from "react";
import imageWoman from "../../assets/woman-2.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { insertUserData } from "../redux/action/user";
import { readBasicInfo } from "../redux/action/basicInfo";
import Moment from "moment";
const BasicInfo = ({location}) => {
  const dipatch = useDispatch();
  const [userData, setUserData] = useState(null);
  const basic_info = useSelector((state) => state.basicInfo.basic_info);

  let today = new Date().toISOString().substr(0, 10);

  const onChangeUserData = (event) => {
    setUserData({
      ...userData,
      [event.target.name]: event.target.value,
    });
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(readBasicInfo());
  }, []);

  useEffect(() => {
  const query = new URLSearchParams(location.search)
  const data = query.get('data')
    if (!data) return;
    try {
      const json = JSON.parse(Buffer.from(data, 'base64').toString())
      const newUserData = {}
      if (typeof json.name === 'string') newUserData.name = json.name;
      if (typeof json.email === 'string') newUserData.email = json.email;
      if (typeof json.birth_date === 'string') newUserData.birth_date = json.birth_date;
      if (['Man', 'Woman', 'Unknown'].includes(json.gender)) newUserData.gender = json.gender;
      setUserData(newUserData)
    } catch (err) {
      setUserData(null)
    }
  }, [location.search])

  const valid = () => {
    if (!userData) return false;
    if (!userData.name) return false;
    if (!userData.email) return false;
    if (!userData.birth_date) return false;
    if (!userData.gender) return false;

    if (!(new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(userData.email))) return false

    return true;
  }

  return (
    <>
      <div className="layout-1">
        <div className="left-wrapper px-64 pt-32">
          {basic_info && (
            <>
              <div className="belleza regular size-60 text-dark-green line-height-85percent">
                {basic_info[0].title}
              </div>
              <div
                style={{ alignSelf: "flex-start" }}
                className="nunito regulat size-24 text-green"
              >
                {basic_info[0].subtitle}
              </div>
            </>
          )}
          <img
            className="heigth-500px width-90vw align-self-flex-end"
            src={basic_info && basic_info[0].image}
            alt="Skin Advisor"
          ></img>
        </div>
        <div className="right-wrapper px-16 py-64 p-32">
          <ul className="steps nunito regular text-disabled size-14">
            <div className="text-green d-flex align-items-center justify-content-flex-end">
              <ion-icon name="ellipse"></ion-icon>---------
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <div className="text-green">--------</div>
              <ion-icon name="ellipse"></ion-icon>--------
            </div>
            <div className="d-flex align-items-center">
              ---------<ion-icon name="ellipse"></ion-icon>
            </div>
            <div className="text-green d-flex align-items-center justify-content-center">
              Basic Info
            </div>
            <div className="d-flex align-items-center justify-content-center">
              Info Kondisi Kulit
            </div>
            <div className="d-flex align-items-center justify-content-center">
              Analisa Kulit
            </div>
          </ul>
          <div
            className="card align-self-flex-start p-64"
            style={{ justifyItems: "normal" }}
          >
            <div className="nunito regular size-20 text-align-center">
              Basic Information Form
            </div>
            <div className="form-group">
              <div className="nunito regular size-14">
                Hai Sahabat Avo, kenalan dulu yuk!
              </div>
              <input
                className="form-control width-400px text-align-center"
                type="text"
                placeholder="Nama Kamu"
                name="name"
                value={(userData && userData.name) || ''}
                onChange={onChangeUserData}
              />
            </div>
            <div className="form-group">
              <div className="nunito regular size-14">
                Email
              </div>
              <input
                className="form-control width-400px text-align-center"
                type="email"
                placeholder="Email kamu"
                name="email"
                value={(userData && userData.email) || ''}
                onChange={onChangeUserData}
              />
            </div>
            <div className="form-group">
              <div className="nunito regular size-14">
                Kapan tanggal lahirmu?
              </div>
              <div
                className="input-group date width-400px position-relative"
                id="datepicker1"
              >
                <input
                  id="birth_date"
                  type="date"
                  className="form-control text-align-center"
                  name="dob" 
                  data-placeholder={userData && userData.birth_date ? null : 'Tanggal/Bulan/Tahun'} 
                  required 
                  aria-required="true"
                  max={today}
                  value={(userData && userData.birth_date && Moment(userData.birth_date, 'DD-MM-YYYY').format('YYYY-MM-DD')) || ''}
                  onChange={(event) => {
                    setUserData({
                      ...userData,
                      birth_date: Moment(event.target.value).format(
                        "DD-MM-YYYY"
                      ),
                    });
                  }}
                />
                <label
                  className="input-group-addon position-absolute p-4px"
                  htmlFor="birth_date"
                  style={{ right: "0", zIndex: "10" }}
                >
                  <ion-icon
                    style={{ fontSize: "28px" }}
                    name="calendar"
                  ></ion-icon>
                </label>
                {/* <input type="date" name="dob" data-placeholder="Tanggal/Bulan/Tahun" required aria-required="true" /> */}
              </div>
            </div>
            <div className="form-group width-400px">
              <div className="nunito regular size-14">Jenis Kelamin</div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="customRadioInline1"
                  name="gender"
                  className="custom-control-input"
                  checked={userData && userData.gender === 'Woman'}
                  onChange={() => setUserData({ ...userData, gender: "Woman" })}
                />
                <label
                  className="custom-control-label nunito regular size-14"
                  htmlFor="customRadioInline1"
                >
                  Perempuan
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="customRadioInline2"
                  name="gender"
                  className="custom-control-input"
                  checked={userData && userData.gender === 'Man'}
                  onChange={() => setUserData({ ...userData, gender: "Man" })}
                />
                <label
                  className="custom-control-label nunito regular size-14"
                  htmlFor="customRadioInline2"
                >
                  Laki - laki
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="customRadioInline3"
                  name="gender"
                  className="custom-control-input"
                  checked={userData && userData.gender === 'Unknown'}
                  onChange={() =>
                    setUserData({ ...userData, gender: "Unknown" })
                  }
                />
                <label
                  className="custom-control-label nunito regular size-14"
                  htmlFor="customRadioInline3"
                >
                  Tidak ingin menjawab
                </label>
              </div>
            </div>
            {!valid() ? (
              <button
                className="btn button-green button-selanjutnya nunito bold size-14 px-32 "
                disabled
              >
                Selanjutnya&ensp;<ion-icon name="arrow-forward"></ion-icon>
              </button>
            ) : (
              <Link to="/kondisi-kulit" className="button-selanjutnya">
                <button
                  className="btn button-green nunito bold size-14 px-32"
                  onClick={() => dipatch(insertUserData(userData))}
                >
                  Selanjutnya&ensp;<ion-icon name="arrow-forward"></ion-icon>
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default BasicInfo;
