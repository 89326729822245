const initialState = {
    info_kondisi_kulit: null,
    loading: false,
  };
  window.__INITIAL_STATE__ = { ...initialState }
  const InfoKondisiKulit = (state = initialState, action) => {
    switch (action.type) {
      case "GET_INFO_KONDISI_KULIT_PENDING":
        return {
          ...state,
          loading: true,
        };
      case "GET_INFO_KONDISI_KULIT_REJECTED":
        return {
          ...state,
          loading: true,
        };
      case "GET_INFO_KONDISI_KULIT_FULFILLED":
        return {
          ...state,
          info_kondisi_kulit: action.payload.data.result,
          loading: false,
        };
      default:
        return state;
    }
  };
  export default InfoKondisiKulit;
  