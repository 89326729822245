import axios from "axios";
require("dotenv").config();

export const readInfoKondisiKulit = () => {
  return {
    type: "GET_INFO_KONDISI_KULIT",
    payload: axios({
      method: "GET",
      url: `${process.env.REACT_APP_API}/info-kondisi-kulit`,
    }),
  };
};