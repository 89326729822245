import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addQuantity, reduceQuantity } from "../redux/action/cart";
import cart from "../../assets/icon/cart.svg";
const RekomendasiProduk = () => {
  const total_quantity = useSelector((state) => state.cart.total_quantity);
  const total_price = useSelector((state) => state.cart.total_price);
  const data = useSelector((state) => state.cart.data);
  const hasilKondisiKulit = JSON.parse(
    localStorage.getItem("hasilKondisiKulit")
  );
  const hasilAlergi = JSON.parse(localStorage.getItem("hasilAlergi"));
  const hasilMasalah = JSON.parse(localStorage.getItem("hasilMasalah"));
  const dispatch = useDispatch();
  const [productList, setProductList] = useState([]);

  useEffect(async () => {
    let productAlergiData = [];
    let productData = [];
    await hasilAlergi.map(async (value) => {
      await value.id_product.map((idProductValue) => {
        return productAlergiData.push(idProductValue);
      });
    });
    await hasilKondisiKulit.map((value) => {
      value.product.map((valueProduct) => {
        let findProduct = productData.findIndex(
          (productValue) => productValue.id === valueProduct.id
        );
        let findAlergiProduct = productAlergiData.findIndex(
          (productAlergiValue) => productAlergiValue === valueProduct.id
        );
        let findCartProduct = data.find(
          (productCartValue) => productCartValue.id === valueProduct.id
        );
        if (
          findProduct === -1 &&
          findAlergiProduct === -1 &&
          findProduct.title !== "Product Not Found"
        ) {
          if (findCartProduct !== undefined || findCartProduct) {
            return productData.push({
              ...valueProduct,
              cart_quantity: findCartProduct.cart_quantity,
            });
          } else {
            return productData.push(valueProduct);
          }
        }
      });
    });
    await hasilMasalah.map((value) => {
      value.product.map((valueProduct) => {
        let findProduct = productData.findIndex(
          (productValue) => productValue.id === valueProduct.id
        );
        let findAlergiProduct = productAlergiData.findIndex(
          (productAlergiValue) => productAlergiValue === valueProduct.id
        );
        let findCartProduct = data.find(
          (productCartValue) => productCartValue.id === valueProduct.id
        );
        if (
          findProduct === -1 &&
          findAlergiProduct === -1 &&
          findProduct.title !== "Product Not Found"
        ) {
          if (findCartProduct !== undefined || findCartProduct) {
            return productData.push({
              ...valueProduct,
              cart_quantity: findCartProduct.cart_quantity,
            });
          } else {
            return productData.push(valueProduct);
          }
        }
      });
    });
    setProductList(productData);
  }, []);

  const onSubmit = () => {
    localStorage.setItem("total_quantity", JSON.stringify(total_quantity));
    localStorage.setItem("total_price", JSON.stringify(total_price));
    localStorage.setItem("cartData", JSON.stringify(data));
    localStorage.setItem("productList", JSON.stringify(productList));
  };
  return (
    <>
      <div className="full-page-wrapper rekomendasi-produk-full-page-wrapper px-64 py-32">
        <div className="card p-32 rekomendasi-product-card">
          <div className="rekomendasi-produk-header-wrapper">
            <Link
              to="/hasil-analisa"
              className="justify-self-flex-start back-button"
            >
              <button className="btn button-outline-green button-pill button-circle nunito bold size-14 text-green">
                <ion-icon name="arrow-back"></ion-icon>
              </button>
            </Link>
            <div className="text-dark-green text-align-center justify-self-center">
              <div className="belleza regular size-60 line-height-85percent">
                Rekomendasi Produk
              </div>
              <div className="nunito light size-20">
                Kamu bisa gunakan produk rekomendasi berikut untuk mengatasi
                masalah kulitmu
              </div>
            </div>
          </div>
          <div className="rekomendasi-all-product-wrapper">
            {productList &&
              productList.map((value) => {
                return (
                  <div className="product-wrapper" key={value.id}>
                    <div className="d-grid justify-items-center align-items-center position-relative">
                      <img
                        className="product-image"
                        src={value.thumbnail}
                        alt={value.title}
                      />
                      <div
                        className={`nunito text-align-center size-16 position-absolute text-white align-self-flex-end out-of-stock-label ${
                          value.stock > 0 && `d-none`
                        }`}
                      >
                        OUT OF STOCK
                      </div>
                    </div>
                    <div className="nunito light size-17">{value.title}</div>
                    <div className="align-items-center product-button-wrapper">
                      <button
                        disabled
                        className="btn button-outline-green button-pill nunito regular size-18 text-green"
                      >
                        {value.cart_quantity}
                      </button>
                      {value.cart_quantity <= 0 ||
                      value.cart_quantity === null ? (
                        <button className="btn p-0" disabled>
                          <ion-icon
                            style={{ color: "#8AA385" }}
                            size="large"
                            name="remove-circle"
                          ></ion-icon>
                        </button>
                      ) : (
                        <button
                          className="btn p-0"
                          onClick={() => {
                            dispatch(reduceQuantity(value));
                          }}
                        >
                          <ion-icon
                            style={{ color: "#8AA385" }}
                            size="large"
                            name="remove-circle"
                          ></ion-icon>
                        </button>
                      )}
                      {value.stock > value.cart_quantity &&
                      value.stock !== null ? (
                        <button
                          className="btn p-0"
                          onClick={() => dispatch(addQuantity(value))}
                        >
                          <ion-icon
                            style={{ color: "#8AA385" }}
                            size="large"
                            name="add-circle"
                          ></ion-icon>
                        </button>
                      ) : (
                        <button className="btn p-0" disabled>
                          <ion-icon
                            style={{ color: "#8AA385" }}
                            size="large"
                            name="add-circle"
                          ></ion-icon>
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
          <Link
            to="/keranjang-belanja"
            className="justify-self-flex-end cart-button"
            onClick={onSubmit}
          >
            <div className="d-grid p-4px" style={{ position: "relative" }}>
              <div className="nunito regular size-15 button-red button-pill">
                {total_quantity}
              </div>
              <img width="60px" height="60px" src={cart} alt="Cart" />
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default RekomendasiProduk;
