const initialState = {
  kondisiKulit: [],
  alergi: [],
  masalah: [],
  hasilKondisiKulit: [],
  hasilAlergi: [],
  hasilMasalah: [],
};

const kondisiKulit = (state = initialState, action) => {
  switch (action.type) {
    case "GET_MASALAH_KULIT_PENDING":
      return {
        ...state,
        loading: true,
      };
    case "GET_MASALAH_KULIT_REJECTED":
      return {
        ...state,
        loading: true,
      };
    case "GET_MASALAH_KULIT_FULFILLED":
      return {
        ...state,
        masalah: action.payload.data.result,
        loading: false,
      };
    case "GET_ALERGI_KULIT_PENDING":
      return {
        ...state,
        loading: true,
      };
    case "GET_ALERGI_KULIT_REJECTED":
      return {
        ...state,
        loading: true,
      };
    case "GET_ALERGI_KULIT_FULFILLED":
      return {
        ...state,
        alergi: action.payload.data.result,
        loading: false,
      };
    case "GET_KONDISI_KULIT_PENDING":
      return {
        ...state,
        loading: true,
      };
    case "GET_KONDISI_KULIT_REJECTED":
      return {
        ...state,
        loading: true,
      };
    case "GET_KONDISI_KULIT_FULFILLED":
      return {
        ...state,
        kondisiKulit: action.payload.data.result,
        loading: false,
      };
    case "GET_HASIL":
      return {
        ...state,
      };
    case "ADD_KONDISI":
      state.hasilKondisiKulit.push(action.payload);
      return {
        ...state,
      };
    case "REMOVE_KONDISI":
      let indexKondisi = state.hasilKondisiKulit.findIndex(
        (value) => value.id === action.payload.id
      );
      state.hasilKondisiKulit.splice(indexKondisi, 1);
      return {
        ...state,
      };
    case "ADD_ALERGI":
      state.hasilAlergi.push(action.payload);
      return {
        ...state,
      };
    case "REMOVE_ALERGI":
      let indexAlergi = state.hasilAlergi.findIndex(
        (value) => value.id === action.payload.id
      );
      state.hasilAlergi.splice(indexAlergi, 1);
      return {
        ...state,
      };
    case "ADD_MASALAH":
      state.hasilMasalah.push(action.payload);
      return {
        ...state,
      };
    case "REMOVE_MASALAH":
      let indexMasalah = state.hasilMasalah.findIndex(
        (value) => value.id === action.payload.id
      );
      state.hasilMasalah.splice(indexMasalah, 1);
      return {
        ...state,
      };
    default:
      return state;
  }
};
export default kondisiKulit;
