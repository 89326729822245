import React, { useEffect, useState } from "react";
import imageWoman from "../../assets/woman-1.png";
import kulitBerminyak from "../../assets/kulit-berminyak.svg";
import { Link } from "react-router-dom";
import { readHome, readHomeCard } from "../redux/action/home";
import { useSelector, useDispatch } from "react-redux";
import {
  readAlergiKulit,
  readKondisiKulit,
  readMasalahKulit,
} from "../redux/action/kondisiKulit";
import { readInfoKondisiKulit } from "../redux/action/infoKondisiKulit";
import { readBasicInfo } from "../redux/action/basicInfo";
const Home = ({location}) => {
  const home = useSelector((state) => state.home.home);
  const home_card = useSelector((state) => state.home.home_card);
  const [carouselActive, setCarouselActive] = useState(0);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(readHome());
    dispatch(readHomeCard());
    dispatch(readBasicInfo());
  }, []);

  return (
    <>
      {home && (
        <div className="layout-1">
          <div>
            <img
              className="heigth-100vh width-100vw"
              src={home[0].image}
              alt={imageWoman}
            ></img>
          </div>
          <div className="right-wrapper py-16 px-64 p-16">
            <div className="title text-align-center">
              <div className="belleza regular size-60 line-height-85percent">
                {home[0].title}
              </div>
              <div className="nunito regular size-24 text-green ">
                {home[0].subtitle}
              </div>
            </div>
            <div className="nunito regular size-16 line-height-40 width-400px">
              {home[0].description}
            </div>

            <div
              id="carouselExampleIndicators"
              className="carousel slide carousel-card-wrapper"
              data-interval="false"
              data-wrap="false"
            >
              <a
                className="custom-carousel-control-prev"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="prev"
              >
                <ion-icon name="chevron-back"></ion-icon>
              </a>
              <div className="carousel-card">
                <div className="card mb-16 width-400px p-32">
                  <div className="carousel-inner">
                    {home_card && (
                      <div className="carousel-item active">
                        <div className="d-grid justify-items-center rg-8">
                          <img
                            width="96px"
                            src={home_card[0].image}
                            alt={kulitBerminyak}
                          />
                          <div className="nunito regular size-16 text-align-center">
                            {home_card[0].description}
                          </div>
                        </div>
                      </div>
                    )}
                    {home_card &&
                      home_card.map((value, index) => {
                        if (index > 0) {
                          return (
                            <div className="carousel-item" key={value.id}>
                              <div className="d-grid justify-items-center rg-8">
                                <img
                                  width="96px"
                                  src={value.image}
                                  alt={kulitBerminyak}
                                />
                                <div className="nunito regular size-16 text-align-center">
                                  {value.description}
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                  </div>
                  <Link
                    to={{
                      pathname: `/basic-info`,
                      search: location.search,
                    }}
                    onClick={() => {
                      dispatch(readAlergiKulit());
                      dispatch(readKondisiKulit());
                      dispatch(readMasalahKulit());
                      dispatch(readInfoKondisiKulit());
                    }}
                  >
                    <button className="btn button-green nunito bold size-14 px-32">
                      Klik Disini
                    </button>
                  </Link>
                </div>
                <div className="carousel-controller quicksand regular size-16">
                  <a
                    className={`control-prev ${
                      carouselActive === 0 && `opacity-0`
                    }`}
                    href={`${
                      carouselActive >= 0 ? `#carouselExampleIndicators` : `/`
                    }`}
                    role="button"
                    data-slide="prev"
                    onClick={() => setCarouselActive(carouselActive - 1)}
                  >
                    <ion-icon name="chevron-back-outline"></ion-icon>
                    &ensp;<span>Sebelumnya</span>
                  </a>
                  <ol className="carousel-indicators carousel-indicators-cards">
                    {home_card &&
                      home_card.map((value, index) => {
                        if (index === 0) {
                          return (
                            <li
                              data-target="#carouselExampleIndicators"
                              data-slide-to="0"
                              className="active"
                              onClick={() => setCarouselActive(0)}
                            ></li>
                          );
                        } else {
                          return (
                            <li
                              data-target="#carouselExampleIndicators"
                              data-slide-to={index}
                              onClick={() => setCarouselActive(index)}
                            ></li>
                          );
                        }
                      })}
                  </ol>
                  {home_card && (
                    <a
                      className={`control-next ${
                        carouselActive === home_card.length - 1 && `opacity-0`
                      }`}
                      href={`${
                        carouselActive <= home_card.length - 1
                          ? `#carouselExampleIndicators`
                          : `/`
                      }`}
                      role="button"
                      data-slide="next"
                      onClick={() => setCarouselActive(carouselActive + 1)}
                    >
                      <span>Selanjutnya</span>&ensp;
                      <ion-icon name="chevron-forward-outline"></ion-icon>
                    </a>
                  )}
                </div>
              </div>
              <a
                className="custom-carousel-control-next"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="next"
              >
                <ion-icon name="chevron-forward"></ion-icon>
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Home;
