import axios from "axios";

export const getTag = (requestBody) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        "https://gserver1.btbp.org/deeptag/AppService.svc/getTags",
        requestBody,
        {
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log("false", error);
        reject(error);
      });
  });
};

export const insertSendTagData = (data) => {
  localStorage.setItem("sendTagData", JSON.stringify(data));
  return {
    type: "INSERT_SEND_TAG_DATA",
    payload: data,
  };
};

export const insertResultTags = (data) => {
  return {
    type: "INSERT_TAGS_RESULT",
    payload: data,
  };
};

export const getResultTags = () => {
  return {
    type: "GET_TAGS_RESULT",
  };
};