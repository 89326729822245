import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { readHome } from "../redux/action/home";
import { getResultTags } from "../redux/action/tag";
const HasilAnalisa = () => {
  const history = useHistory();
  const hasilKondisiKulit = JSON.parse(
    localStorage.getItem("hasilKondisiKulit")
  );
  const hasilMasalah = JSON.parse(localStorage.getItem("hasilMasalah"));
  const resultTags = JSON.parse(localStorage.getItem("resultTags"));
  const imageUrl = JSON.parse(localStorage.getItem("imageUrl"));
  const home = useSelector((state) => state.home.home);

  const [activeAnalisa, setActiveAnalisa] = useState({});
  const [newHasilKondisiKulit, setNewHasilKondisiKulit] = useState(null);
  const [imageActive, setImageActive] = useState(null);

  const dispatch = useDispatch();

  const onChangeActive = async (data) => {
    let masalahActive = null;
    if (data.tags[0] === "WRINKLES_IMAGE_FAST") {
      let findTags = resultTags.find(
        (value) => value[0].TagName === "WRINKLES_IMAGE_FAST"
      );
      masalahActive = {
        ...data,
        image_tag: findTags[0],
      };
    } else {
      let findTags = resultTags.find(
        (value) => value[0].TagName === data.tags[0]
      );
      masalahActive = {
        ...data,
        image_tag: findTags[0],
      };
    }
    // if (data.tags[0] === "WRINKLES_IMAGE_FAST") {
    //     let findTags = resultTags.find(
    //         (value) => value[1].TagName === "WRINKLES_IMAGE_FAST"
    //   );
    //   masalahActive = {
    //       ...data,
    //     image_tag: findTags[1],
    //     scoring_tag: findTags[0],
    //   };
    // } else {
    //     let findTags = resultTags.find(
    //     (value) => value[0].TagName === data.tags[1]
    //   );
    //   masalahActive = {
    //       ...data,
    //     image_tag: findTags[1],
    //     scoring_tag: findTags[0],
    //   };
    // }
    setActiveAnalisa(masalahActive);
    setImageActive(
      "data:image/png;base64," + masalahActive.image_tag.TagValues[0].Value
    );
    history.push("/hasil-analisa");
  };
  useEffect(async () => {
    let kondisiKulit = [];
    setActiveAnalisa(hasilKondisiKulit[0]);
    dispatch(readHome());
    dispatch(getResultTags());

    await hasilKondisiKulit.map((value) => {
      kondisiKulit.push({ ...value, user_image: imageUrl });
    });

    setNewHasilKondisiKulit(kondisiKulit);
    setImageActive(kondisiKulit[0].user_image);
  }, []);
  return (
    <>
      <div className="full-page-wrapper hasil-analisa-wrapper">
        <div className="text-align-center hasil-analisa-left-wrapper">
          {home && (
            <>
              <div className="belleza regular size-40 text-dark-green">
                {home[0].title}
              </div>
              <div className="nunito light size-16 text-green mb-25">
                {home[0].subtitle}
              </div>
            </>
          )}
          <img
            style={{
              borderRadius: "10px",
              maxWidth: "25vw",
              maxHeight: "70vh",
            }}
            src={imageActive}
            alt="Hasil Analisa"
          />
        </div>
        <div className="text-align-center hasil-analisa-right-wrapper">
          <div className="top-hasil-analisa-right-wrapper">
            <div className="belleza regular size-40 text-dark-green">
              Hasil Analisa
            </div>
            <div className="nunito light size-16 text-green mb-25">
              Yuk kita lihat problem kulitmu dan kita lihat produk yang tepat
              untukmu
            </div>
            <img
              style={{ borderRadius: "10px", maxWidth: "75vw" }}
              src={imageActive}
              alt="Hasil Analisa"
              className="hasil-analisa-image"
            />
          </div>
          <div className="card hasil-analisa-card-wrapper p-0">
            <div className="list-hasil-analisa-wrapper">
              <div className="list-hasil-analisa-card-wrapper">
                {newHasilKondisiKulit &&
                  newHasilKondisiKulit.map((value) => {
                    return (
                      <button
                        key={value.id}
                        className={`${
                          activeAnalisa.name === value.name && `active`
                        } card align-items-center d-inline-grid nunito regular size-16 p-8`}
                        style={{ gridTemplateColumns: "max-content 1fr" }}
                        onClick={() => {
                          setActiveAnalisa(value);
                          setImageActive(value.user_image);
                        }}
                      >
                        <img width="40px" src={value.image} alt={value.name} />
                        <div className="justify-self-flex-start text-green">
                          {value.name}
                        </div>
                      </button>
                    );
                  })}
                {hasilMasalah &&
                  hasilMasalah.map((value) => {
                    return (
                      <button
                        className={`${
                          activeAnalisa.name === value.name && `active`
                        } card align-items-center d-inline-grid nunito regular size-16 p-8`}
                        style={{ gridTemplateColumns: "max-content 1fr" }}
                        onClick={() => {
                          onChangeActive(value);
                        }}
                      >
                        <img width="40px" src={value.image} alt={value.name} />
                        <div className="justify-self-flex-start text-green">
                          {value.name}
                        </div>
                      </button>
                    );
                  })}
              </div>
            </div>
            <div className="description-hasil-analisa-card-wrapper">
              <div className="nunito bold size-16 text-green">
                {activeAnalisa && activeAnalisa.name}
              </div>
              <div className="nunito light size-14 line-height-32">
                {activeAnalisa && activeAnalisa.description}
              </div>
              <div className="d-inline-grid align-self-flex-end">
                <Link
                  to="/analisa-kulit"
                  className="justify-self-center"
                  onClick={() => localStorage.setItem("pageView", true)}
                >
                  <button className="btn button-white nunito bold size-14 text-green text-align-right button-navigasi-hasil-analisa">
                    <ion-icon name="arrow-back"></ion-icon>&ensp; Kembali
                    Analisa Kulit
                  </button>
                </Link>
                <Link to="/rekomendasi-produk" className="justify-self-center">
                  <button className="btn button-green nunito bold size-14 text-green text-align-left button-navigasi-hasil-analisa">
                    Rekomendasi Produk&ensp;
                    <ion-icon name="arrow-forward"></ion-icon>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HasilAnalisa;
