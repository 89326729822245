const initialState = {
  userData: null,
  loading: false,
  sendData: null,
};
window.__INITIAL_STATE__ = { ...initialState };
const User = (state = initialState, action) => {
  switch (action.type) {
    case "INSERT_USER_PENDING":
      return {
        ...state,
        loading: true,
      };
    case "INSERT_USER_REJECTED":
      return {
        ...state,
        loading: true,
      };
    case "INSERT_USER_FULFILLED":
      return {
        ...state,
        loading: false,
      };
    case "INSERT_USER_DATA":
      return {
        ...state,
        userData: action.payload,
        sendData: { ...state.sendData, name: action.payload.name },
        loading: false,
      };
    default:
      return state;
  }
};
export default User;
