import axios from "axios";
require("dotenv").config();

export const readHome = () => {
  return {
    type: "GET_HOME",
    payload: axios({
      method: "GET",
      url: `${process.env.REACT_APP_API}/home`,
    }),
  };
};

export const readHomeCard = () => {
  return {
    type: "GET_HOME_CARD",
    payload: axios({
      method: "GET",
      url: `${process.env.REACT_APP_API}/home-card`,
    }),
  };
};
