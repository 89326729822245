import axios from "axios";
require("dotenv").config();

export const readMasalahKulit = () => {
  return {
    type: "GET_MASALAH_KULIT",
    payload: axios({
      method: "GET",
      url: `${process.env.REACT_APP_API}/masalah-kulit`,
    }),
  };
};

export const readAlergiKulit = () => {
  return {
    type: "GET_ALERGI_KULIT",
    payload: axios({
      method: "GET",
      url: `${process.env.REACT_APP_API}/alergi-kulit`,
    }),
  };
};

export const readKondisiKulit = () => {
  return {
    type: "GET_KONDISI_KULIT",
    payload: axios({
      method: "GET",
      url: `${process.env.REACT_APP_API}/kondisi-kulit`,
    }),
  };
};

export const readHasil = () => {
  return {
    type: "GET_HASIL",
  };
};

export const addKondisi = (data) => {
  return {
    type: "ADD_KONDISI",
    payload: data,
  };
};

export const removeKondisi = (data) => {
  return {
    type: "REMOVE_KONDISI",
    payload: data,
  };
};

export const addAlergi = (data) => {
  return {
    type: "ADD_ALERGI",
    payload: data,
  };
};

export const removeAlergi = (data) => {
  return {
    type: "REMOVE_ALERGI",
    payload: data,
  };
};

export const addMasalah = (data) => {
  return {
    type: "ADD_MASALAH",
    payload: data,
  };
};

export const removeMasalah = (data) => {
  return {
    type: "REMOVE_MASALAH",
    payload: data,
  };
};
