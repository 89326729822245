const initialState = {
  data: [],
  total_quantity: 0,
  total_price: 0,
};

window.__INITIAL_STATE__ = { ...initialState };
const cart = (state = initialState, action) => {
  let index = state.data.findIndex((value) => {
    if (action.payload) {
      return value.id === action.payload.id;
    }
  });

  switch (action.type) {
    case "ADD_QUANTITY":
      if (state.data.find((value) => value.id === action.payload.id)) {
        state.data[index].cart_quantity = state.data[index].cart_quantity + 1;
        state.data[index].total_price_product =
          state.data[index].cart_quantity * state.data[index].price;
        state.total_price = state.total_price + state.data[index].price;
      } else {
        action.payload.cart_quantity = action.payload.cart_quantity + 1;
        action.payload.total_price_product = action.payload.price;
        state.data.push(action.payload);
        state.total_price = state.total_price + action.payload.price;
      }
      return {
        ...state,
        total_quantity: state.total_quantity + 1,
      };
    case "REDUCE_QUANTITY":
      if (state.data[index] !== undefined) {
        if (state.data[index].id && state.data[index].cart_quantity > 1) {
          state.data[index].cart_quantity = state.data[index].cart_quantity - 1;
          state.data[index].total_price_product =
            state.data[index].cart_quantity * state.data[index].price;
          state.total_quantity = state.total_quantity - 1;
          state.total_price = state.total_price - state.data[index].price;
        } else if (state.data[index].cart_quantity === 1) {
          state.data[index].total_price_product = 0;
          state.data[index].cart_quantity = 0;
          state.total_price = state.total_price - state.data[index].price;
          state.data.splice(index, 1);
          state.total_quantity = state.total_quantity - 1;
        }
      }
      return {
        ...state,
      };
    default:
      return state;
  }
};
export default cart;
