export const addQuantity = (data) => {
  return {
    type: "ADD_QUANTITY",
    payload: data,
  };
};

export const reduceQuantity = (data) => {
  return {
    type: "REDUCE_QUANTITY",
    payload: data,
  };
};
