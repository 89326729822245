const initialState = {
  home: null,
  home_card: null,
  loading: false,
};
window.__INITIAL_STATE__ = { ...initialState }
const Home = (state = initialState, action) => {
  switch (action.type) {
    case "GET_HOME_PENDING":
      return {
        ...state,
        loading: true,
      };
    case "GET_HOME_REJECTED":
      return {
        ...state,
        loading: true,
      };
    case "GET_HOME_FULFILLED":
      return {
        ...state,
        home: action.payload.data.result,
        loading: false,
      };
    case "GET_HOME_CARD_PENDING":
      return {
        ...state,
        loading: true,
      };
    case "GET_HOME_CARD_REJECTED":
      return {
        ...state,
        loading: true,
      };
    case "GET_HOME_CARD_FULFILLED":
      return {
        ...state,
        home_card: action.payload.data.result,
        loading: false,
      };
    default:
      return state;
  }
};
export default Home;
