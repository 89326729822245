const initialState = {
  data: null,
};
window.__INITIAL_STATE__ = { ...initialState }
const image = (state = initialState, action) => {
  switch (action.type) {
    case "INSERT_IMAGE_URL":
      state.data = action.payload;
      return {
        ...state,
      };
    default:
      return state;
  }
};
export default image;
