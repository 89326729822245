import axios from "axios";
require("dotenv").config();

export const readBasicInfo = () => {
  return {
    type: "GET_BASIC_INFO",
    payload: axios({
      method: "GET",
      url: `${process.env.REACT_APP_API}/basic-info`,
    }),
  };
};