const initialState = {
    basic_info: null,
    loading: false,
  };
  window.__INITIAL_STATE__ = { ...initialState }
  const BasicInfo = (state = initialState, action) => {
    switch (action.type) {
      case "GET_BASIC_INFO_PENDING":
        return {
          ...state,
          loading: true,
        };
      case "GET_BASIC_INFO_REJECTED":
        return {
          ...state,
          loading: true,
        };
      case "GET_BASIC_INFO_FULFILLED":
        return {
          ...state,
          basic_info: action.payload.data.result,
          loading: false,
        };
      default:
        return state;
    }
  };
  export default BasicInfo;
  