import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  readAlergiKulit,
  readKondisiKulit,
  readMasalahKulit,
  addKondisi,
  addMasalah,
  addAlergi,
  removeKondisi,
  removeMasalah,
  removeAlergi,
} from "../redux/action/kondisiKulit";
import { insertUserData } from "../redux/action/user";
import { insertSendTagData } from "../redux/action/tag";
import { readInfoKondisiKulit } from "../redux/action/infoKondisiKulit";
import { useHistory } from "react-router-dom";
import imageWoman from "../../assets/woman-3.png";
const KondisiKulit = () => {
  const kondisiKulit = useSelector((state) => state.kondisiKulit.kondisiKulit);
  const alergi = useSelector((state) => state.kondisiKulit.alergi);
  const masalah = useSelector((state) => state.kondisiKulit.masalah);
  const hasilKondisiKulit = useSelector(
    (state) => state.kondisiKulit.hasilKondisiKulit
  );
  const hasilAlergi = useSelector((state) => state.kondisiKulit.hasilAlergi);
  const hasilMasalah = useSelector((state) => state.kondisiKulit.hasilMasalah);
  const infoKondisiKulit = useSelector(
    (state) => state.infoKondisiKulit.info_kondisi_kulit
  );
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [kandunganLainnya, setKandunganLainnya] = useState(true);

  const [cardView, setCardView] = useState(1);
  const [data, setData] = useState(userData);

  const dispatch = useDispatch();
  const history = useHistory();

  const changeKondisi = (value) => {
    if (
      hasilKondisiKulit.find((valueKondisi) => valueKondisi.id === value.id)
    ) {
      dispatch(removeKondisi(value));
    } else if (hasilKondisiKulit.length < 2) {
      dispatch(addKondisi(value));
    }
    history.push("/kondisi-kulit");
  };

  const changeAlergi = (value) => {
    if (hasilAlergi.find((valueAlergi) => valueAlergi.id === value.id)) {
      dispatch(removeAlergi(value));
    } else {
      dispatch(addAlergi(value));
    }
    history.push("/kondisi-kulit");
  };

  const changeMasalah = (value) => {
    if (hasilMasalah.find((valueMasalah) => valueMasalah.id === value.id)) {
      dispatch(removeMasalah(value));
    } else {
      dispatch(addMasalah(value));
    }
    history.push("/kondisi-kulit");
  };

  const changeKandunganLainnya = (event) => {
    let description = "" || event.target.value;
    setData({
      ...data,
      alergi_kulit_user_description: event.target.value,
    });
    let lainnya = alergi.find(
      (valueAlergi) => valueAlergi.name.toLowerCase() === "lainnya"
    );
    if (description.length === 1 && kandunganLainnya === true) {
      dispatch(addAlergi(lainnya));
      setKandunganLainnya(false);
    } else if (description.length === 0 && kandunganLainnya === false) {
      dispatch(removeAlergi(lainnya));
      setKandunganLainnya(true);
    }
    history.push("/kondisi-kulit");
  };

  const onSubmit = async () => {
    let newData = {};
    let kondisi_kulit = [];
    let alergi_kulit = [];
    let masalah_kulit = [];

    let tags = [];

    await hasilKondisiKulit.map((value) => kondisi_kulit.push(value.id));
    await hasilAlergi.map((value) => alergi_kulit.push(value.id));
    await hasilMasalah.map(
      (value) => (masalah_kulit.push(value.id), tags.push(value.tags))
    );

    newData = {
      ...data,
      kondisi_kulit: kondisi_kulit,
      alergi_kulit: alergi_kulit,
      masalah_kulit: masalah_kulit,
    };

    dispatch(insertUserData(newData));

    const newSendTagData = {
      tags: tags,
    };

    dispatch(insertSendTagData(newSendTagData));
    localStorage.setItem(
      "hasilKondisiKulit",
      JSON.stringify(hasilKondisiKulit)
    );
    localStorage.setItem("hasilAlergi", JSON.stringify(hasilAlergi));
    localStorage.setItem("hasilMasalah", JSON.stringify(hasilMasalah));
    localStorage.setItem("pageView", true);
    history.push("/analisa-kulit");
  };
  useEffect(() => {
    dispatch(readAlergiKulit());
    dispatch(readKondisiKulit());
    dispatch(readMasalahKulit());
    dispatch(readInfoKondisiKulit());
  }, []);

  return (
    <>
      <div className="layout-1">
        <div className="left-wrapper px-64 pt-16">
          <div className="belleza regular size-60 text-dark-green">
            {infoKondisiKulit && infoKondisiKulit[0].title}
          </div>
          <div
            style={{ alignSelf: "flex-start" }}
            className="nunito regulat size-20 text-green"
          >
            {infoKondisiKulit && infoKondisiKulit[0].subtitle}
          </div>
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-ride="carousel"
          >
            <ol className="carousel-indicators">
              {infoKondisiKulit &&
                infoKondisiKulit[0].image.map((value, index) => {
                  if (index === 0) {
                    return (
                      <li
                        data-target="#carouselExampleIndicators"
                        data-slide-to="0"
                        className="active"
                      ></li>
                    );
                  } else {
                    return (
                      <li
                        data-target="#carouselExampleIndicators"
                        data-slide-to={index}
                        className="active"
                      ></li>
                    );
                  }
                })}
            </ol>
            <div className="carousel-inner width-550px width-90vw">
              {infoKondisiKulit &&
                infoKondisiKulit[0].image.map((value, index) => {
                  if (index === 0) {
                    return (
                      <div className="carousel-item active">
                        <img
                          className="width-550px width-90vw"
                          src={value.image}
                          alt="Skin Advisor"
                        ></img>
                      </div>
                    );
                  } else {
                    return (
                      <div className="carousel-item">
                        <img
                          className="width-550px width-90vw"
                          src={value.image}
                          alt="Skin Advisor"
                        ></img>
                      </div>
                    );
                  }
                })}
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
        <div className="right-wrapper kondisi-kulit-right-wrapper px-16 py-64 p-32">
          <ul className="steps nunito regular text-disabled">
            <div className="d-flex align-items-center justify-content-flex-end text-disabled">
              <ion-icon name="ellipse"></ion-icon>---------
            </div>
            <div className="d-flex align-items-center justify-content-center text-disabled">
              --------
              <div className="text-green d-flex align-items-center">
                <ion-icon name="ellipse"></ion-icon>
                --------
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="text-green">---------</div>
              <ion-icon name="ellipse"></ion-icon>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              Basic Info
            </div>
            <div className="text-green d-flex align-items-center justify-content-center">
              Info Kondisi Kulit
            </div>
            <div className="d-flex align-items-center justify-content-center">
              Analisa Kulit
            </div>
          </ul>
          <div
            className="card info-kondisi-kulit-card align-self-flex-start p-32"
            style={{ justifyItems: "normal" }}
          >
            <div className="nunito regular size-20 text-align-center">
              Info Kondisi Kulit
            </div>
            {cardView === 1 ? (
              <>
                <div className="form-group-kondisi-kulit">
                  <div className="nunito regular size-14">
                    Bagaimana Kondisi Kulitmu ?
                  </div>
                  <div className="button-kondisi-kulit-wrapper mt-25">
                    {kondisiKulit.map((value) => {
                      return (
                        <button
                          key={value.id}
                          type="button"
                          className={`btn button-outline-green button-pill nunito regular size-14 ${
                            hasilKondisiKulit.find(
                              (valueKondisi) => valueKondisi.id === value.id
                            ) && `active`
                          }`}
                          onClick={() => {
                            changeKondisi(value);
                          }}
                        >
                          {value.name}
                        </button>
                      );
                    })}
                  </div>
                  <div className="nunito italic size-14 text-green mt-25 mb-16">
                    *Pilih maksimal 2 kondisi sesuai keadaan kulitmu
                  </div>
                </div>
                <div className="form-group">
                  <div className="nunito regular size-14">
                    Apakah ada kandungan skincare berikut ini yang memicu alergi
                    pada kulitmu?
                  </div>
                  <div className="button-kondisi-kulit-wrapper mt-25">
                    {alergi.map((value) => {
                      if (value.name.toLowerCase() !== "lainnya") {
                        return (
                          <button
                            key={value.id}
                            type="button"
                            className={`btn button-outline-green button-pill nunito regular size-14 ${
                              hasilAlergi.find(
                                (valueAlergi) => valueAlergi.id === value.id
                              ) && `active`
                            }`}
                            onClick={() => changeAlergi(value)}
                          >
                            {value.name}
                          </button>
                        );
                      }
                    })}
                    {alergi.map((value) => {
                      if (value.name.toLowerCase() === "lainnya") {
                        return (
                          <button
                            key={value.id}
                            type="button"
                            className={`btn button-outline-green button-pill nunito regular size-14 button-lainnya ${
                              hasilAlergi.find(
                                (valueAlergi) => valueAlergi.id === value.id
                              ) && `active`
                            }`}
                            onClick={() => changeAlergi(value)}
                          >
                            {value.name}
                          </button>
                        );
                      }
                    })}
                    <input
                      type="text"
                      name="alergi_kulit_user_description"
                      className="form-control kandungan-lainnya text-align-center"
                      placeholder="Kandungan lainnya"
                      value={data && data.alergi_kulit_user_description}
                      onChange={changeKandunganLainnya}
                    ></input>
                  </div>
                </div>
                <div className="bottom-card-kondisi-kulit-wrapper">
                  <div className="page-card-kondisi-kulit-wrapper d-flex nunito regular size-14">
                    <div className="btn text-disabled">
                      <ion-icon name="chevron-back"></ion-icon>
                    </div>
                    <button className="btn button-green button-pill button-circle">
                      1
                    </button>
                    &ensp;
                    <button
                      className="btn button-outline-green button-pill button-circle text-green"
                      onClick={() => setCardView(2)}
                    >
                      2
                    </button>
                    <button
                      className="btn text-green"
                      onClick={() => setCardView(2)}
                    >
                      <ion-icon name="chevron-forward"></ion-icon>
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="form-group">
                  <div className="nunito regular size-14">
                    Masalah apa yang terjadi pada kulitmu?
                  </div>
                  <div className="button-kondisi-kulit-wrapper mt-25">
                    {masalah.map((value) => {
                      if (value.show_masalah === "Show") {
                        return (
                          <button
                            key={value.id}
                            type="button"
                            className={`btn button-outline-green button-pill nunito regular size-14 ${
                              hasilMasalah.find(
                                (valueMasalah) => valueMasalah.id === value.id
                              ) && `active`
                            }`}
                            onClick={() => changeMasalah(value)}
                          >
                            {value.name}
                          </button>
                        );
                      }
                    })}
                  </div>
                  <div className="nunito italic size-14 text-green mt-25 mb-16">
                    *Bisa pilih lebih dari 1
                  </div>
                </div>
                <div className="bottom-card-kondisi-kulit-wrapper align-items-center">
                  <div className="page-card-kondisi-kulit-wrapper d-flex nunito regular size-14">
                    <button
                      className="btn text-green"
                      onClick={() => setCardView(1)}
                    >
                      <ion-icon name="chevron-back"></ion-icon>
                    </button>
                    <button
                      className="btn button-outline-green button-pill button-circle text-green"
                      onClick={() => setCardView(1)}
                    >
                      1
                    </button>
                    &ensp;
                    <button className="btn button-green button-pill button-circle">
                      2
                    </button>
                    <button className="btn text-disabled">
                      <ion-icon name="chevron-forward"></ion-icon>
                    </button>
                  </div>
                  {hasilAlergi.length < 1 ? (
                    <button
                      className="btn button-green nunito bold size-14 px-32 justify-self-flex-end"
                      disabled
                    >
                      Selanjutnya&ensp;
                      <ion-icon name="arrow-forward"></ion-icon>
                    </button>
                  ) : hasilKondisiKulit.length < 1 ? (
                    <button
                      className="btn button-green nunito bold size-14 px-32 justify-self-flex-end"
                      disabled
                    >
                      Selanjutnya&ensp;
                      <ion-icon name="arrow-forward"></ion-icon>
                    </button>
                  ) : hasilMasalah.length < 1 ? (
                    <button
                      className="btn button-green nunito bold size-14 px-32 justify-self-flex-end"
                      disabled
                    >
                      Selanjutnya&ensp;
                      <ion-icon name="arrow-forward"></ion-icon>
                    </button>
                  ) : (
                    <button
                      className="btn button-green nunito bold size-14 px-32 justify-self-flex-end"
                      onClick={onSubmit}
                    >
                      Selanjutnya&ensp;
                      <ion-icon name="arrow-forward"></ion-icon>
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default KondisiKulit;
