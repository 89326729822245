import { combineReducers } from "redux";
import cart from "./cart";
import image from "./image";
import kondisiKulit from "./kondisiKulit";
import user from "./user";
import home from "./home";
import tag from "./tag"
import basicInfo from "./basicInfo"
import infoKondisiKulit from "./infoKondisiKulit"
export default combineReducers({
  cart,
  image,
  kondisiKulit,
  user,
  home,
  tag,
  basicInfo,
  infoKondisiKulit
});
