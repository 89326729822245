import axios from "axios";
require("dotenv").config();

export const insertUser = (data) => {
  return {
    type: "INSERT_USER",
    payload: axios({
      method: "POST",
      url: `${process.env.REACT_APP_API}/user`,
      data: data,
    }),
  };
};

export const insertUserData = (data) => {
  localStorage.setItem("userData", JSON.stringify(data));
  return {
    type: "INSERT_USER_DATA",
    payload: data,
  };
};
