import React, { useState, useCallback, useRef, useEffect } from "react";
import { insertImageUrl } from "../redux/action/image";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { readHome } from "../redux/action/home";
import { getTag, insertResultTags } from "../redux/action/tag";
import { Modal } from "reactstrap";
import Webcam from "react-webcam";
import arrowUp from "../../assets/icon/arrow-up.svg";
import replay from "../../assets/icon/replay.svg";
import submit from "../../assets/icon/paper-airplane.svg";
import buttonCircle from "../../assets/button/button-circle.svg";
import buttonCircleMuted from "../../assets/button/button-circle-muted.svg";
import photoDefault from "../../assets/photo-default.png";
import UnggahFoto from "../../assets/icon/unggah-foto.svg";
import { insertUser } from "../redux/action/user";
import useAsync from '@alifaishol/use-async';

const getAnalisis = async () => {
  const response = await fetch(`${process.env.REACT_APP_API}/analisis`)
  return response.json();
}

const AnalisaKulit = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const home = useSelector((state) => state.home.home);
  const tags = JSON.parse(localStorage.getItem("sendTagData"));
  const resultTags = useSelector((state) => state.tag.resultTags);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [pageView, setPageView] = useState(true);
  const [imageView, setImageView] = useState("default");
  const [nameView, setNameView] = useState("webcam");
  const [scanner, setScanner] = useState(false);
  const [screenWidth, setScreenWidth] = useState(null);
  const [screenHeight, setScreenHeight] = useState(null);
  const [showModal, setShowModal] = useState(true);
  const [modalError, setModalError] = useState(false);
  const [modalErrorResponse, setModalErrorResponse] = useState(false);
  const [messageErrorResponse, setMessageErrorResponse] = useState(null);

  const [imageUrl, setImageUrl] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [imageName, setImageName] = useState(null);
  const videoConstraints = {
    // width: screenWidth,
    // height: screenHeight,
    facingMode: "user",
  };
  const webcamRef = useRef(null);

  const {exec: getAnalisisExec, state: getAnalisisState} = useAsync(getAnalisis)

  const capture = useCallback(async () => {
    let image = null;
    const imageSrc = webcamRef.current.getScreenshot();
    image = imageSrc.split(",");
    setImageUrl(imageSrc);
    setImageData(image[1]);
    setImageView("image");
    setImageName("capture.jpeg");
    setNameView("capture");
    dispatch(insertResultTags(null));
  }, [webcamRef, setImageUrl]);

  const onInsertImage = async (event) => {
    let image = null;
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        image = reader.result.split(",");
        setImageUrl(reader.result);
        setImageView("image");
        setImageName(event.target.files[0].name);
        setNameView("upload image");
        setImageData(image[1]);
        dispatch(insertResultTags(null));
        setPageView(false);
      }
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  useEffect(async () => {
    dispatch(readHome());

    let sW = window.screen.width;
    let sH = window.screen.height;
    if (sW <= 600) {
      setScreenWidth(sW);
      setScreenHeight(sH);
    } else {
      setScreenWidth("100%");
      setScreenHeight("100%");
    }
    setPageView(JSON.parse(localStorage.getItem("pageView")));

    let constraints = { audio: false, video: true };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(function (stream) {
        console.log("Camera On", stream);
      })
      .catch(function (err) {
        setModalErrorResponse(true);
        setMessageErrorResponse(
          "Gagal terhubung dengan kamera! Silahkan upload foto anda"
        );
        console.log(err);
      });
  }, []);

  const onRestart = async () => {
    window.location.href = "/analisa-kulit";
    let constraints = { audio: false, video: true };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(function (stream) {
        console.log("Camera On", stream);
        setImageView("default");
        setNameView("webcam");
        setImageUrl(null);
        setImageData(null);
      })
      .catch(function (err) {
        setModalErrorResponse(true);
        setMessageErrorResponse(
          "Gagal terhubung dengan kamera! Silahkan upload foto anda"
        );
        console.log(err);
      });
    localStorage.setItem("pageView", false);
  };

  const kirimkan = async () => {
    setScanner(true);
    setShowModal(true);
    processTag(imageData, tags.tags);
    localStorage.setItem("imageUrl", JSON.stringify(imageUrl));
  };

  const processTag = async (base64, imageTag) => {
    await imageTag.map((value) => {
      const requestBody = {
        APIKey: "D2TS-ZIH5-VKC4-IQBX",
        Tags: value,
        ImageBytes: [base64],
      };

      getTag(requestBody)
        .then((result) => {
          console.log(result.data);
          imageTagSuccessCallback(result.data);
        })
        .catch((error) => {
          console.log("Error tag", error);
          setScanner(false);
          if (showModal === true) {
            setModalError(true);
            setShowModal(false);
          }
          console.log(showModal);
        });
    });
  };

  useEffect(() => {
    getAnalisisExec();
  }, [getAnalisisExec])

  const imageTagSuccessCallback = async (tagResponse) => {
    let isCallSuccess = await tagResponse.Tags.every((tag) => {
      return tag.StatusCode === 200;
    });

    if (isCallSuccess) {
      dispatch(insertResultTags(tagResponse.Tags));
      localStorage.setItem("resultTags", JSON.stringify(resultTags));
      if (resultTags.length === tags.tags.length) {
        dispatch(insertUser(userData));
        history.push("/hasil-analisa");
      }
    } else {
      setScanner(false);
      setModalErrorResponse(true);
      setMessageErrorResponse(
        "Kami tidak bisa mendeteksi wajah anda. Mungkin kualitas foto anda terlalu rendah! Silahkan upload foto anda"
      );
      return;
    }
  };

  const analisis = getAnalisisState.data && getAnalisisState.data.result[0]

  return (
    <>
      {pageView === true && (
        <div className="analisa-kulit-wrapper">
          <div className="card text-align-center width-550px analisa-kulit-introduce">
            <div className="nunito regular size-24">{(analisis && analisis.title) || ''}</div>
            <div className="nunito regular size-14">
              {(analisis && analisis.description) || ''}
            </div>
            <img height="180px" src={(analisis && analisis.image) || UnggahFoto} alt="Uggah Foto"></img>
            <div className="nunito regular size-14 text-green">
              {(analisis && analisis.warning) || ''}
            </div>
            <button
              onClick={() => {
                setPageView(false);
              }}
              className="btn button-green nunito bold size-14 px-32"
            >
              Mulai Sekarang
            </button>
          </div>
        </div>
      )}
      <div
        className={`full-page-wrapper px-64 ${pageView === true && `blur-10`} ${
          screenWidth < 600 && `position-relative`
        }`}
        style={{ overflow: "hidden" }}
      >
        <div
          className={`d-grid justify-self-center image-analisa-kulit position-relative 
          `}
        >
          {imageView === "default" ? (
            <Webcam
              videoConstraints={videoConstraints}
              width={100 + "vh"}
              height={100 + "vh"}
              audio={false}
              screenshotFormat="image/jpeg"
              ref={webcamRef}
              mirrored={true}
              screenshotQuality={1}
              className="justify-self-center webcam"
            />
          ) : scanner === false ? (
            <>
              <div className="d-grid image-capture-wrapper">
                <div className="nunito bold size-14 button-black button-pill px-32 py-8 image-name">
                  {imageName}
                </div>
                <img
                  className="justify-self-center image-capture"
                  src={imageUrl}
                  alt="Analisa Kulit Image"
                />
              </div>
            </>
          ) : (
            <>
              <div className="anim-box">
                <div className="scanner"></div>
                <img
                  className="justify-self-center image-capture"
                  style={{
                    opacity: "0.4",
                  }}
                  src={imageUrl}
                  alt="Analisa Kulit Image"
                />
              </div>
            </>
          )}
        </div>
        <div className="bottom-card-container">
          <div className="bottom-card-wrapper">
            <div className="text-white text-align-center bottom-card-title-mobile">
              <div className="belleza regular size-24">
                {home && home[0].title}
              </div>
              <div className="nunito light size-8">
                {home && home[0].subtitle}
              </div>
            </div>
            {imageView === "image" && (
              <div className="nunito bold button-black button-pill size-14 image-name-mobile">
                {imageName}
              </div>
            )}
            <div className="bottom-card px-32">
              <div className="text-green text-align-center bottom-card-title">
                <div className="belleza regular size-33">
                  {home && home[0].title}
                </div>
                <div className="nunito light size-10">
                  {home && home[0].subtitle}
                </div>
              </div>
              <div
                className={`${
                  scanner === false ? `d-inline-grid` : `gc-1-4`
                } align-items-center capture-wrapper`}
              >
                {scanner === false ? (
                  <>
                    <input
                      type="file"
                      id="upload-analisa-kulit"
                      accept="image/*"
                      onChange={onInsertImage}
                    />
                    <div className="d-grid justify-content-center">
                      <label
                        htmlFor="upload-analisa-kulit"
                        className="btn button-green button-pill nunito bold size-14 button-analisa-kulit unggah"
                      >
                        <img height="14px" src={arrowUp} alt="Unggah Foto" />
                        <div className="button-text-analisa-kulit">
                          Unggah Foto
                        </div>
                      </label>
                      <div className="nunito bold size-12 button-sub-text text-green">
                        Upload
                      </div>
                    </div>
                    {nameView === "webcam" ? (
                      <button className={`btn capture`} onClick={capture} onChange={capture}>
                        <img
                          className="justify-self-center button-capture"
                          src={buttonCircle}
                          alt="Button Capture"
                        />
                      </button>
                    ) : (
                      <div
                        className={`btn capture`}
                        style={{ cursor: "default" }}
                      >
                        <img
                          className="justify-self-center button-capture"
                          src={buttonCircleMuted}
                          alt="Button Capture"
                        />
                      </div>
                    )}
                    {nameView === "webcam" ? (
                      <div className="d-grid justify-content-center">
                        <button
                          disabled
                          className="button-analisa-kulit justify-self-flex-start btn button-grey button-pill nunito bold size-14"
                        >
                          <img height="14px" src={replay} alt="Ulangi" />
                          <div className="button-text-analisa-kulit">
                            Ulangi
                          </div>
                        </button>
                        <div className="nunito bold size-12 button-sub-text text-grey">
                          Restart
                        </div>
                      </div>
                    ) : (
                      <div className="d-grid justify-content-center">
                        <button
                          onClick={onRestart}
                          className="button-analisa-kulit justify-self-flex-start btn button-green button-pill nunito bold size-14"
                        >
                          <img height="14px" src={replay} alt="Ulangi" />
                          <div className="button-text-analisa-kulit">
                            Ulangi
                          </div>
                        </button>
                        <div className="nunito bold size-12 button-sub-text text-green">
                          Restart
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="analisa-kulit-loading-bar">
                    <div
                      className="nunito regular size-20"
                      style={{ marginBottom: "8px" }}
                    >
                      Sedang Menganalisa Fotomu..
                    </div>
                    <div className="progress">
                      <div
                        className="progress-bar progress-bar-danger"
                        style={{ width: "100%" }}
                      ></div>
                    </div>
                  </div>
                )}
              </div>
              {imageUrl === null ? (
                <div
                  className={`${
                    scanner === false ? `d-grid` : `d-none`
                  } justify-content-center`}
                >
                  <button
                    className="btn button-white nunito bold size-14 text-green button-analisa-kulit button-submit"
                    disabled
                  >
                    <div className="button-text-analisa-kulit">
                      Kirimkan&ensp;
                      <ion-icon name="arrow-forward"></ion-icon>
                    </div>
                    <img
                      height="14px"
                      src={submit}
                      alt="Unggah Foto"
                      className="paper-plane-icon"
                    />
                  </button>
                  <div className="nunito bold size-12 button-sub-text text-disabled">
                    Submit
                  </div>
                </div>
              ) : (
                <div
                  className={`${
                    scanner === false ? `d-grid` : `d-none`
                  } justify-content-center`}
                >
                  <button
                    className="btn button-white nunito bold size-14 text-green button-analisa-kulit button-submit"
                    onClick={() => {
                      kirimkan();
                      dispatch(insertImageUrl(imageUrl));
                    }}
                  >
                    <div className="button-text-analisa-kulit">
                      Kirimkan&ensp;
                      <ion-icon name="arrow-forward"></ion-icon>
                    </div>
                    <img
                      height="14px"
                      src={submit}
                      alt="Unggah Foto"
                      className="paper-plane-icon"
                    />
                  </button>
                  <div
                    className="nunito bold size-12 button-sub-text"
                    style={{ color: "white" }}
                  >
                    Submit
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalError}
        toggle={() => {
          setModalError(!modalError);
        }}
        className="modal-sm"
      >
        <div className="modal-body text-align-center">
          Error get image tag from BTBP!
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setModalError(!modalError);
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={modalErrorResponse}
        toggle={() => {
          setModalErrorResponse(!modalErrorResponse);
        }}
        className="modal-md"
      >
        <div className="modal-body text-align-center d-grid">
          <div>{messageErrorResponse}</div>
          <button
            className="btn d-grid justify-content-center mt-16"
            onClick={() => {
              setModalErrorResponse(!modalErrorResponse);
            }}
          >
            <label
              htmlFor="upload-analisa-kulit"
              className="btn button-green button-pill nunito bold size-14"
              style={{ width: "max-content" }}
            >
              <img height="14px" src={arrowUp} alt="Unggah Foto" />
              <div>Unggah Foto</div>
            </label>
          </button>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setModalErrorResponse(!modalErrorResponse);
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    </>
  );
};
export default AnalisaKulit;
