const INITIAL_STATE = {
  tags: null,
  resultTags: [],
};

const Tag = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "INSERT_SEND_TAG_DATA":
      return {
        ...state,
        tags: action.payload.tags,
        resultTags: [],
        loading: false,
      };
    case "INSERT_TAGS_RESULT":
      if (action.payload === null) {
        state.resultTags = [];
      } else {
        state.resultTags.push(action.payload);
      }
      return {
        ...state,
        loading: false,
      };
    case "GET_TAGS_RESULT":
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default Tag;
