import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { parseToRupiah } from "../helpers/index";
import { useSelector } from "react-redux";
const KeranjangBelanja = () => {
  const data = JSON.parse(localStorage.getItem("cartData"));
  const total_price = JSON.parse(localStorage.getItem("total_price"));
  const sendData = JSON.parse(localStorage.getItem("userData"));

  const [sendDataFinal, setSendDataFinal] = useState(null);
  useEffect(() => {
    let productData = [];

    data.map((value) => {
      return productData.push({ id: value.id, qty: value.cart_quantity });
    });
    setSendDataFinal({
      ...sendData,
      product: productData,
    });
  }, []);

  let objJsonStr = JSON.stringify(sendDataFinal);
  let encode = Buffer.from(objJsonStr).toString("base64");

  return (
    <>
      <div className="full-page-wrapper keranjang-belanja-wrapper">
        <div className="card keranjang-belanja-card">
          <div className="rekomendasi-produk-header-wrapper">
            <Link
              to="/rekomendasi-produk"
              className="justify-self-flex-start back-button"
            >
              <button className="btn button-outline-green button-pill button-circle nunito bold size-14 text-green">
                <ion-icon name="arrow-back"></ion-icon>
              </button>
            </Link>
            <div className="text-dark-green text-align-center justify-self-center">
              <div className="belleza regular size-60">Keranjang Belanja</div>
            </div>
          </div>
          <div className="d-grid keranjang-belanja-content-wrapper">
            <div className="product-table">
              {data.map((value) => {
                return (
                  <>
                    <div className="text-align-center">
                      <img
                        src={value.thumbnail}
                        alt={value.title}
                        className="product-image"
                      />
                    </div>
                    <div className="product-name">
                      <div className="nunito regular size-20">
                        {value.title}
                      </div>
                      <div className="d-inline-grid price-x-quantity">
                        <div className="nunito regular size-16 text-green">
                          {parseToRupiah(value.price)}
                        </div>
                        <div className="nunito regular size-16 text-red">
                          x{value.cart_quantity}
                        </div>
                      </div>
                      <div className="nunito semi-bold size-16 text-green product-price-merge">
                        {parseToRupiah(value.total_price_product)}
                      </div>
                    </div>
                    <div className="nunito semi-bold size-16 text-green text-align-center product-price">
                      {parseToRupiah(value.total_price_product)}
                    </div>
                  </>
                );
              })}
              <div className="table-footer">
                <div className="nunito black size-16 text-green justify-self-center">
                  TOTAL
                </div>
                <div className="nunito black size-16 text-green text-align-center">
                  {parseToRupiah(total_price)}
                </div>
              </div>
            </div>
            <button
              className="justify-self-flex-end align-self-flex-end btn button-green nunito bold size-14 px-32 text-green mt-25 button-checkout"
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_AVOSKIN_URL}/skin-advisor-checkout/?data=${encode}`,
                  "_blank"
                );
              }}
            >
              Check Out&ensp;
              <ion-icon name="arrow-forward"></ion-icon>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default KeranjangBelanja;
