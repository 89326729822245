import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import Home from "./components/pages/home";
import BasicInfo from "./components/pages/basicInfo";
import KondisiKulit from "./components/pages/kondisiKulit";
import AnalisaKulit from "./components/pages/analisaKulit";
import RekomendasiProduk from "./components/pages/rekomendasiProduk";
import KeranjangBelanja from "./components/pages/keranjangBelanja";
import HasilAnalisa from "./components/pages/hasilAnalisa";
import store from "./components/redux/store";
import "./App.css";
import "./components/css/layout.css";
import "./components/css/wrapper.css";
import "./components/css/text.css";
import "./components/css/images.css";
import "./components/css/cards.css";
import "./components/css/button.css";
import "./components/css/carousel.css";
import "./components/css/margin.css";
import "./components/css/padding.css";
import "./components/css/input.css";
import "./components/css/size.css";
import "./components/css/position.css";
import "./components/css/steps.css";
import "./components/css/otherComponents.css";
import "./components/css/filter.css";
import "./components/css/scanner.css";
import "./components/css/loader.css";
export default function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/basic-info" component={BasicInfo} />
          <Route path="/kondisi-kulit" component={KondisiKulit} />
          <Route path="/analisa-kulit" component={AnalisaKulit} />
          <Route path="/hasil-analisa" component={HasilAnalisa} />
          <Route path="/rekomendasi-produk" component={RekomendasiProduk} />
          <Route path="/keranjang-belanja" component={KeranjangBelanja} />
        </Switch>
      </Router>
    </Provider>
  );
}
